define("discourse/plugins/discourse-gamification/discourse/routes/admin-plugins-gamification", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/routes/discourse"], function (_exports, _object, _ajax, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    model() {
      if (!this.currentUser?.admin) {
        return {
          model: null
        };
      }
      return (0, _ajax.ajax)("/admin/plugins/gamification.json").then(model => {
        model.leaderboards = model.leaderboards.map(leaderboard => _object.default.create(leaderboard));
        return model;
      });
    }
  });
});